﻿@import "../config";

.m-property-list
{
	margin: 0;
	padding: 0;
	text-align: left;
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		width: auto !important;
		max-width: 100% !important;
		&__item
		{
			display: inline-block;
			font-size: $fontSize;
			height: 300px;
			line-height: $fontHeight;
			margin: 0 auto;
			padding: 0;
			vertical-align: top;
			width: 100%;
			max-width: 1200px;
			@include mq-min($bpMobileLarge)
			{
				height: 400px !important;
			}
			@include mq-max($bpTabletLarge - 1)
			{
				width: 100% !important;
			}
			@include mq-min($bpTabletLarge)
			{
				height: 500px !important;
				width: 50%;
				@include mq-max($bpDesktop - 1)
				{
					width: 50% !important;
				}
			}
			@include mq-min($bpDesktop)
			{
				height: 600px !important;
			}
			&--of-1
			{
				width: 100% !important;
			}
		}
	}
}