﻿@import "../config";

.m-property {
	margin: 0;
	padding: 0;

	&__intro {
		margin: 0;
		padding: 0;

		&__inner {
			margin: 0;
			padding: 0;
		}

		&__content {
			@include box-sizing();
			margin: 0;
			overflow: hidden;
			padding: 15px;

			@include mq-min($bpTabletLarge) {
				padding: 20px;
			}

			@include mq-min($bpDesktop) {
				padding: 30px;
			}

			@include mq-min($bpDesktopLarge) {
				padding: 40px;
			}

			@include mq-min($bpLarge) {
				padding: 50px;
			}
		}

		&__image {
			display: none;
			float: left;
			height: auto;
			margin: -15px 0 0 0;
			padding: 0;
			width: 50%;
			max-width: 600px;

			@include mq-min($bpTabletLarge) {
				display: block;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding-bottom: 20px;
			padding-top: 15px;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 35px;
		}

		@include mq-min($bpLarge) {
			padding-bottom: 50px;
		}

		&--tight {
			margin-bottom: -10px;

			@include mq-min($bpTabletLarge) {
				margin-bottom: -25px;
			}

			@include mq-min($bpDesktop) {
				margin-bottom: -25px;
			}

			@include mq-min($bpLarge) {
				margin-bottom: -35px;
			}
		}
	}

	&__location {
		background: $colorWhite;
		@include box-sizing();
		margin: 0;
		padding: 15px;
		position: relative;
		z-index: 1;

		&__title {
			@include font-heading-gamma();
			margin: 0;
			padding: 0 0 15px 0;
		}

		&__inner {
			background: $colorWhite;
			margin: 0;
			padding: 15px 15px 0 15px;
			position: relative;
			z-index: 2;

			@include mq-min($bpTabletLarge) {
				margin-left: 30%;
				padding: 20px 20px 5px 20px;
			}

			@include mq-min($bpDesktop) {
				@include min-height(250px);
				margin-left: 40%;
				padding: 30px 30px 10px 30px;
			}

			@include mq-min($bpDesktopLarge) {
				margin-left: 50%;
				padding: 40px 40px 15px 40px;
			}

			@include mq-min($bpLarge) {
				padding: 50px 50px 20px 50px;
			}
		}

		&__image {
			background: no-repeat 100% 100%;
			@include background-size(cover);
			bottom: 0;
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			width: 100%;
			z-index: 1;

			@include mq-max($bpTabletLarge - 1) {
				position: static;
				height: 300px;
				margin-left: -15px;
				margin-right: -15px;
				width: calc(100% + 30px);
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 0 0 80px 0;
		}

		@include mq-min($bpDesktop) {
			padding-bottom: 110px;
		}

		@include mq-min($bpDesktopLarge) {
			padding-bottom: 140px;
		}
	}

	&__resort {
		background: $colorWhite;
		@include box-sizing();
		margin: 0;
		padding: 15px;
		position: relative;
		z-index: 1;

		&__title {
			@include font-heading-gamma();
			margin: 0;
			padding: 0 0 15px 0;
		}

		&__inner {
			background: $colorWhite;
			margin: 0;
			padding: 15px;
			position: relative;
			z-index: 2;

			@include mq-min($bpTabletLarge) {
				margin-right: 20%;
				padding: 20px;
			}

			@include mq-min($bpDesktop) {
				margin-right: 25%;
				padding: 30px;
			}

			@include mq-min($bpDesktopLarge) {
				margin-right: 33.3333%;
				padding: 40px;
			}

			@include mq-min($bpLarge) {
				padding: 50px;
			}
		}

		&__image {
			background: no-repeat 100% 100%;
			@include background-size(cover);
			bottom: 0;
			display: block;
			height: 100%;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			width: 100%;
			z-index: 1;

			@include mq-max($bpTabletLarge - 1) {
				position: static;
				height: 300px;
				margin-left: -15px;
				margin-right: -15px;
				width: calc(100% + 30px);
			}
		}

		@include mq-min($bpTabletLarge) {
			border-bottom: 20px solid $colorWhite;
			padding: 80px 0 0 0;
		}

		@include mq-min($bpDesktop) {
			border-bottom-width: 35px;
			padding-top: 110px;
		}

		@include mq-min($bpDesktopLarge) {
			border-bottom-width: 40px;
			padding-top: 340px;
		}
	}
}