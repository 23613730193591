﻿@import "../config";

.m-feature-grid
{
	margin: 0;
	padding: 15px 0 60px 0;
	&__inner
	{
		font-size: 0;
		line-height: 0;
		margin: -12px 0 0 -12px;
		padding: 0;
		position: relative;
		text-align: center;
		z-index: 1;
		&:before, &:after
		{
			border-left: 2px solid $colorDivider;
			bottom: 0;
			content: "";
			display: none;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 12px;
			width: 1px;
			z-index: 1;
			@include mq-min($bpDesktop)
			{
				display: block;
			}
		}
		&:before
		{
			left: 33.3333%;
			margin-left: 7px;
		}
		&:after
		{
			margin-right: -6px;
			right: 33.3333%;
		}
	}
	&__col
	{
		@include box-sizing();
		display: inline-block;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 12px 0 0 12px;
		vertical-align: top;
		width: 100%;
		@include mq-min($bpTablet)
		{
			width: 50%;
		}
		@include mq-min($bpDesktop)
		{
			width: 33.3333%;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 20px 0 60px 0;
	}
	@include mq-min($bpDesktop)
	{
		padding: 35px 0;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 40px 0;
	}
}