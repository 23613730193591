﻿@import "../config";

#popupFade
{
	background: $colorBlack;
	background: rgba($colorBlack, .5);
	display: none;
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9990;
}

.m-popup
{
	background: $colorWhite;
	@include box-shadow();
	@include box-sizing();
	display: none;
	left: 50%;
	margin: 0 0 0 -320px;
	padding: 0;
	position: absolute;
	top: 50px;
	width: 640px;
	z-index: 9999;
	&__title
	{
		margin: 0;
		padding: 0 0 15px 0;
	}
	&__inner
	{
		@include min-height(200px);
		margin: 0;
		padding: 10px 15px 20px 15px;
		position: relative;
		text-align: left;
		z-index: 1;
		@include mq-min($bpTabletLarge)
		{
			padding: 15px 20px 25px 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 30px 35px 40px 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 45px 50px 55px 50px;
		}
	}
	&__video
	{
		background: $colorBlack;
		height: 0;
		margin: 0 auto;
		overflow: hidden;
		padding: 35px 0 56.25% 0;
		position: relative;
		z-index: 1;
		iframe
		{
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}
	&__actions
	{
		background: lighten($colorOffWhite, 5%);
		@include border-radius-adv(0, 0, 2px, 2px);
		border-top: 1px solid $colorDivider;
		margin: 0 -15px -15px -15px;
		padding: 15px;
		&__menu
		{
			font-size: 0;
			line-height: 0;
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: center;
			&__item
			{
				display: inline-block;
				font-size: $fontSize;
				line-height: $fontHeight;
				list-style: none;
				margin: 0;
				padding: 10px;
				.form-button
				{
					margin: 0;
				}
			}
		}
		@include mq-min($bpDesktopLarge)
		{
			margin: 0 -20px -20px -20px;
		}
	}
	&__close
	{
		@include bg('/images/common/sprite.png', no-repeat, -47px 0);
		display: block;
		height: 29px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: -39px;
		@include text-hidden();
		top: 0;
		width: 29px;
		z-index: 3;
		&:hover
		{
			@include opacity();
		}
		@include mq-min($bpTabletLarge)
		{
			right: -44px;
		}
	}
	@include mq-min($bpDesktop)
	{
		margin-left: -400px;
		width: 800px;
	}
	@include mq-min($bpDesktopLarge)
	{
		margin-left: -450px;
		width: 900px;
	}
	@include mq-min($bpLarge)
	{
		margin-left: -500px;
		width: 1000px;
	}
	&--video
	{
		&__inner
		{
			padding: 3px;
		}
	}
	&--confirm
	{
		margin-left: -140px;
		width: 280px;
		&__inner
		{
			@include min-height(100px);
		}
		@include mq-min($bpTablet)
		{
			margin-left: -180px;
			width: 360px;
		}
		@include mq-min($bpDesktop)
		{
			margin-left: -200px;
			width: 400px;
		}
	}
}

#popupLoader
{
	@include bg('/images/common/loader.gif', no-repeat, 50% 50%);
	background-color: $colorWhite;
	@include border-radius(100%);
	@include box-shadow();
	@include box-sizing();
	display: none;
	height: 52px;
	left: 50%;
	margin: -26px 0 0 -26px;
	padding: 0;
	position: fixed;
	top: 50%;
	width: 52px;
	z-index: 9998;
}