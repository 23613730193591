﻿@import "../config";

.m-contact-links
{
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 0 0 15px 0;
	text-align: center;
	&__item
	{
		@include box-sizing();
		color: $colorPrimary;
		display: inline-block;
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 5px 0 0 0;
		position: relative;
		vertical-align: top;
		width: 100%;
		z-index: 1;
		a
		{
			color: $colorPrimary;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: underline;
			}
		}
		&:before
		{
			background: lighten($colorPrimary, 40%);
			content: "";
			display: none;
			height: 12px;
			left: 15px;
			margin: -6px 0 0 0;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 1px;
			z-index: 1;
			@include mq-min($bpMobileLarge)
			{
				display: block;
			}
		}
		@include mq-min($bpMobileLarge)
		{
			padding: 0 0 0 31px;
			width: auto;
			max-width: 50%;
		}
		&:first-child
		{
			&:before
			{
				display: none;
			}
			@include mq-min($bpMobileLarge)
			{
				padding-left: 0;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 30px;
	}
	&--tight
	{
		padding-bottom: 0;
	}
}