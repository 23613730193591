@import "../config";

.l-header {
	@include box-sizing();
	margin: 0;
	height: 67px;
	padding: 0;
	position: relative;
	width: 100%;
	z-index: 100;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__logo {
		display: block;
		margin: 0 auto;
		padding: 40px 0 10px 0;
		width: 159px;

		@include mq-max($bpTabletLarge - 1) {
			width: 103px
		}

		&__link {
			@include bg('/images/logos/sanzaru.png', no-repeat, 50% 50%);
			display: block;
			height: 124px;
			margin: 0;
			padding: 0;
			position: relative;
			@include text-hidden();
			z-index: 1;
			background-size: 100% 100%;

			@include mq-max($bpTabletLarge - 1) {
				height: 80px;
			}

			&:hover {
				@include opacity();
			}
		}
	}

	&--transparent {
		background: none;
	}
}