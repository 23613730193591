﻿@import "../config";

.m-feature
{
	margin: 0;
	padding: 10px 15px;
	text-align: center;
	&__title
	{
		color: $colorPrimary;
		font-size: $fontSize + 6;
		line-height: $fontHeight + 3;
		margin: 0 auto 15px auto;
		padding: 0 0 10px 0;
		position: relative;
		@include text-overflow();
		text-transform: uppercase;
		font-weight: 600;
		max-width: 300px;
		z-index: 1;
		&:before
		{
			@include bar-underline($colorAction, 1px, 40px, 50%, -20px);
		}
		&__link
		{
			color: $colorPrimary;
			text-decoration: none;
			&:hover
			{
				color: $colorPrimary;
				text-decoration: none;
			}
		}
		@include mq-min($bpDesktop)
		{
			font-size: $fontSize + 6;
			line-height: $fontHeight + 3;
			margin-bottom: 20px;
		}
	}
	&__summary
	{
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
		margin: 0 auto;
		overflow: hidden;
		padding: 0;
		max-width: 300px;
		font-weight: 600;
		@include mq-min($bpTablet)
		{
			@include min-height(70px);
		}
	}
	&__actions
	{
		margin: 0;
		padding: 15px 0 0 0;
		@include mq-min($bpDesktop)
		{
			padding-top: 20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 25px;
		}
		.form-button {

			@include mq-max($bpTabletLarge - 1)
			{
				width: calc(100% - 35px);
				margin-left: auto;
				margin-right: auto;
			}
		}
		&--no-offset
		{
				padding-top: 5px;
			@include mq-min($bpDesktop)
			{
				padding-top: 10px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-top: 15px;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 15px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 20px 15px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 25px 15px;
	}
	&--cta
	{
		background: darken($colorOffWhite, 10%);
		padding: 50px 15px;
		&__summary
		{
			@include mq-min($bpTablet)
			{
				@include min-height(20px);
			}
			@include mq-min($bpDesktop)
			{
				@include min-height(35px);
			}
		}
		&__actions
		{
			@include mq-min($bpDesktop)
			{
				padding-top: 5px;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding: 25px 15px;
		}
		@include mq-min($bpDesktop)
		{
			@include min-height(80px);
			padding: 30px 15px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 35px 15px;
		}
		@include mq-min($bpDesktopLarge)
		{
			@include min-height(85px);
		}
		&--mid
		{
			background: darken($colorOffWhite, 5%);
		}
		&--light
		{
			background: $colorOffWhite;
		}
	}
}