﻿@import "../config";

.l-back-to-top
{
	background: $colorPrimary;
	border-bottom: 1px solid $colorWhite;
	border-bottom: 1px solid rgba($colorWhite, .3);
	color: $colorWhite;
	font-size: $fontSize - 2;
	line-height: $fontHeight - 2;
	margin: 0;
	padding: 0;
	text-align: center;
	&__link
	{
		color: $colorWhite;
		display: block;
		font-weight: bold;
		margin: 0;
		padding: 0;
		text-decoration: none;
		text-transform: uppercase;
		&__inner
		{
			display: inline-block;
			margin: 0 auto;
			padding: 10px 10px 10px 23px;
			position: relative;
			z-index: 1;
			&:before
			{
				@include arrow-up(6px);
				left: 10px;
				position: absolute;
				top: 17px;
				z-index: 1;
			}
		}
		&:hover
		{
			color: $colorWhite;
			text-decoration: none;
		}
	}
	@include mq-min($bpDesktop)
	{
		display: none;
	}
}