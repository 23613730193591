﻿@import "../config";

.m-explore
{
	margin: 0;
	padding: 40px 0 0 0;
	text-align: center;
	&__content
	{
		display: none;
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 1;
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 0;
			position: relative;
			vertical-align: top;
			width: 50%;
			max-width: 600px;
			z-index: 1;
			@include mq-min($bpTablet)
			{
				width: 25%;
			}
			.js &
			{
				cursor: pointer;
				&:hover, &.active
				{
					.m-explore__menu__overlay
					{
						background: rgba($colorAccent, 0.95);
					}	
				}
			}
		}
		&__overlay
		{
			background-color: rgba($colorAccent,0.4);
			@include box-sizing();
			color: $colorWhite;
			font-size: $fontSize + 2;
			line-height: $fontHeight - 2;
			font-weight: 400;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 15px;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
			transition: background-color 300ms;
			&__inner
			{
				position: relative;
				@include transform(translateY(-50%));
				top: 50%;
				z-index: 1;
			}
			&__title
			{
				color: $colorWhite;
				display: block;
				font-size: $fontSize + 2;
				font-weight: 600;
				line-height: $fontHeight + 2;
				margin: 0;
				padding: 0;
				@include text-overflow();
				text-transform: uppercase;
				@include mq-min($bpTabletLarge)
				{
					font-size: $fontSize + 4;
					line-height: $fontHeight + 4;
				}
				@include mq-min($bpDesktop)
				{
					font-size: $fontSize + 6;
					line-height: $fontHeight + 6;
				}
				@include mq-min($bpDesktopLarge)
				{
					font-size: $fontSize + 8;
					line-height: $fontHeight + 8;
				}
			}
		}
		&__image
		{
			height: auto;
			margin: 0;
			padding: 0;
			width: auto;
			z-index: 1;
		}
	}
	&__gallery
	{
		background: $colorGreyOffWhite;
		display: none;
		margin: 10px 0 0 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-align: left;
		z-index: 1;
		&__images
		{
			@include box-sizing();
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;
			@include mq-min($bpDesktop)
			{
				float: left;
				@include min-height(480px);
				width: 66.6666%;
			}
			@include mq-min($bpDesktopLarge)
			{
				@include min-height(540px);
			}
			@include mq-min($bpLarge)
			{
				@include min-height(600px);
			}
		}
		&__menu
		{
			margin: 0;
			padding: 0;
			&__item
			{
				display: none;
				margin: 0;
				padding: 0;
				&:first-child
				{
					display: block;
				}
			}
			&__image
			{
				height: auto;
				width: 100%;
			}
		}
		&__loader
		{
			background: $colorGreyOffWhite;
			@include box-sizing();
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 10px;
			position: absolute;
			text-align: center;
			@include text-hidden();
			top: 0;
			width: 100%;
			z-index: 2000;

			&:before {
				content: '';
				border-radius: 50%;
				width: 60px;
				height: 60px;
				margin: 0 auto;
				border-top: 6px solid rgba($colorBlack, 0.1);
				border-right: 6px solid rgba($colorBlack, 0.1);
				border-bottom: 6px solid rgba($colorBlack, 0.1);
				border-left: 6px solid $colorBlack;
				transform: translateZ(0);
				animation: loadingSpinner 1.1s infinite linear;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -30px 0 0 -30px;
				@include text-hidden();
				display: block;
				@include box-sizing();
			}
		}
		&__summary
		{
			@include box-sizing();
			margin: 0;
			overflow: hidden;
			padding: 15px;
			&__inner
			{
				height: 100%;
				@include mq-min($bpDesktop)
				{
					overflow-y: auto;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				padding: 20px;
			}
			@include mq-min($bpDesktop)
			{
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				width: 33.3333%;
				z-index: 1;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding: 35px;
			}
		}
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 40px 0 0 0;
	}
}

.m-explore__title {
	@include font-heading-alpha();
}

.m-explore__menu__overlay__name {
	font-style: normal;
}

.m-explore__gallery {

	.bx-prev,
	.bx-next {
		background: none;
	}
}