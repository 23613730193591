﻿@import "../config";

.m-share
{
	margin: 0 auto;
	padding: 0 0 15px 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__title
	{
		font-size: $fontSize;
		line-height: $fontHeight;
		margin: 0;
		padding: 0 0 10px 0;
		&--white
		{
			color: $colorWhite;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0 0 0 -10px;
		padding: 0;
		@include mq-min($bpTabletLarge)
		{
			margin: -10px 0 0 -10px;
		}
		&__item
		{
			@include box-sizing();
			display: inline-block;
			margin: 0;
			padding: 0 0 0 10px;
			vertical-align: top;
		}
		&__link__icon {
			display: block;
			margin: 0 auto;
		}
		&__link
		{
			display: flex;
			align-items: center;
			height: 40px;
			margin: 0;
			padding: 0;
			position: relative;
			width: 40px;
			z-index: 1;
			&:hover
			{
				opacity: 0.8;
			}
			@include mq-min($bpTabletLarge)
			{
				height: 50px;
				width: 50px;
			}
			&--large
			{
				@include mq-min($bpDesktop)
				{
					height: 60px;
					width: 60px;
				}
			}
			&--white
			{
				color: $colorWhite;
				&:hover {
					color: $colorWhite;
				}
			}
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}