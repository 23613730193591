@import "../config";

// wrapper
.form-wrapper
{
	margin: 0 auto;
	padding: 0 0 20px 0;
	text-align: left;
	&:last-child, &--tight
	{
		padding-bottom: 0;
	}
	&--divider
	{
		border-bottom: 1px solid $colorDivider;
		margin: 0 0 20px 0;
		padding: 0 0 20px 0;
		&:last-child
		{
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	&--centralised
	{
		text-align: center;
		max-width: 450px;
	}
}

// row
.form-row
{
	@include box-sizing();
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 0 0 15px 0;
	text-align: left;
	> * 
	{
		font-size: $fontSize;
		line-height: $fontHeight;
	}
	&--divider
	{
		border-bottom: 1px solid $colorDivider;
		margin: 0 0 15px 0;
		padding: 0 0 15px 0;
		&:last-child
		{
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	&--inline
	{
		margin: 0;
		padding: 0;
		.form-row
		{
			@include mq-min($bpDesktop)
			{
				float: left;
				padding: 0 10px 0 0;
				width: 33.3333%;
				max-width: 153px;
			}
			&--half
			{
				@include mq-min($bpDesktop)
				{
					width: 50%;
					max-width: 230px;
				}				
			}
		}
		@include mq-min($bpDesktop)
		{
			margin: 0 -10px 0 0;
			padding: 0 0 15px 0;
		}
	}
	&--group
	{
		padding-bottom: 5px;
		.form-row
		{
			padding-bottom: 10px;
		}
	}
}

// action
.form-action
{
	font-size: 0;
	line-height: 0;
	margin: 0;
	padding: 10px 0 0 0;
	* 
	{
		font-size: $fontSize;
		line-height: $fontHeight;
	}
	&:first-child
	{
		padding-top: 0;
	}
}

// labels
label
{
	.form-row &
	{
		display: block;
		padding-bottom: 5px;
		text-align: left;
	}
}

// required
.form-required
{
	color: $colorRed !important;
	font-family: $fontSimple;
}

// inputs
.form-text, .form-textarea, .form-select
{
	border: 1px solid $colorBorder;
	@include border-radius(0);
	@include box-sizing();
	@include box-shadow(none);
	color: $colorBase;
	display: inline-block;
	font-family: $fontSimple;
	font-size: $fontSize;
	height: 38px;
	line-height: $fontHeight;
	margin: 0;
	padding: 5px 10px;
	@include placeholder-text($colorBase, rgba($colorBase,.8));
	vertical-align: middle;
	width: 100%;
	max-width: 450px;
	&[type=search] 
	{
		-webkit-appearance: textfield;
	}
	&:hover 
	{
		border-color: darken($colorBorder, 10%);
	}
	&:focus 
	{
		border-color: darken($colorBorder, 20%);
		@include box-shadow(0 0 5px 0 rgba($colorBorder,.5));
	}
	&.disabled, &[disabled]
	{
		background: lighten($colorOffWhite, 5%);
		border-color: $colorBorder !important;
		cursor: not-allowed;
		@include opacity();
		@include placeholder-text($colorBase, rgba($colorBase, .25));
	}
	&.input-validation-error
	{
		background-color: lighten($colorRed, 42%);
		border-color: $colorRed;
		color: $colorRed;
		@include placeholder-text($colorRed, rgba($colorRed,.8));
		&:focus 
		{
			background-color: $colorWhite;
			border-color: darken($colorBorder, 20%);
			color: $colorBase;
			@include placeholder-text($colorBase, rgba($colorBase,.8));
		}
	}
	&--small
	{
		margin-right: 10px;
		width: 30.3%;
		max-width: 150px;
	}
	&--medium
	{
		margin-right: 10px;
		width: 65%;
		max-width: 300px;
	}
	&--large
	{
		margin-right: 10px;
		width: 85%;
		max-width: 450px;
	}
}

.form-text
{
	-webkit-appearance: none;
}

.form-textarea 
{
	height: 150px;
	padding: 8px 10px;
	resize: vertical;
  vertical-align: top;
	-webkit-appearance: none;
}

.form-select
{
	padding: 5px;
}

// checks/radios
.form-checks
{
	font-size: 0;
	line-height: 0;
	list-style: none;
	margin: 0;
	padding: 0 0 15px 0;
	text-align: left;
	max-width: 450px;
	&__item
	{
		@include box-sizing();
		font-size: $fontSize;
		line-height: $fontHeight;
		list-style: none;
		margin: 0;
		padding: 5px 0 0 0;
		label
		{
			cursor: pointer;
			display: inline-block;
			@include min-height(15px);
			margin: 0;
			padding: 0 0 0 20px;
			position: relative;
			z-index: 1;
			input
			{
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 3px;
				z-index: 1;
				&:focus 
				{
					outline: 1px dotted $colorLink;
				}
			}
		}
		&:first-child
		{
			padding-top: 0;
		}
		&--spaced
		{
			padding-top: 15px;
		}
	}
	&--inline
	{
		margin-top: -5px;
		&__item
		{
			display: inline-block;
			padding-top: 5px;
			vertical-align: top;
			width: 50%;
			&:first-child
			{
				padding-top: 5px;
			}
			&--full
			{
				width: 100%;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 0;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}

// buttons
.form-button
{
	background: $colorLink no-repeat 50% 50%;
	border: 1px solid darken($colorLink, 10%);
	@include border-radius(0);
	@include box-sizing();
	color: $colorWhite;
	cursor: pointer;
	display: inline-block;
	font-family: $fontBase;
	font-size: $fontSize;
	font-weight: normal;
	line-height: $fontSize;
	margin: 0 10px 0 0;
	padding: 10px 15px;
	position: relative;
  text-align: center;
	text-decoration: none !important;
	@include user-select();
	vertical-align: middle;
  white-space: nowrap;
	min-width: 150px;
	z-index: 2;
	&:hover 
	{
		background-color: darken($colorLink, 10%);
		border-color: darken($colorLink, 10%);
		color: $colorWhite;
		text-decoration: none;
	}
	&:focus 
	{
		outline: 1px dotted darken($colorLink, 10%);
	}
	&:last-child
	{
		margin-right: 0;
	}
	&.disabled, &[disabled] 
	{
		background: $colorDivider !important;
		border-color: $colorDivider !important;
		color: $colorGrey !important;
		cursor: not-allowed !important;
		@include opacity(.8);
	}
}

.form-button
{
	&--tight
	{
		margin-left: 0;
	}
	&--inline
	{
		margin-left: 0;
		padding: 11px 20px;
		min-width: 10px;
	}
	&--large
	{
		@include mq-min($bpTabletLarge)
		{
			font-size: $fontSize + 2;
			line-height: $fontSize + 2;
		}
	}
	&--block
	{
		display: block;
		margin: 0;
		width: 100%;
	}
}

// info
.form-info
{
	clear: both;
	color: $colorGrey;
	display: block;
	font-size: $fontSize - 1;
	line-height: $fontHeight - 1;
	margin: 0;
	padding: 5px 0 0 0;
	text-align: left;
	max-width: 450px;
	a
	{
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
	}
}

.form-error,
.form-success
{
	background: $colorRed;
	@include box-sizing();
	clear: both;
	color: $colorWhite;
	display: block;
	font-size: $fontSize;
	line-height: $fontHeight;
	margin: 0 0 15px 0;
	padding: 10px 15px;
	position: relative;
	text-align: left;
	z-index: 1;
	a 
	{
		color: $colorWhite;
		text-decoration: underline;
		&:hover
		{
			color: $colorWhite;
			text-decoration: none;
		}
	}
	.form-row &, .form-checks__item &
	{
		background: none;
		color: $colorRed;
		clear: both;
		display: none;
		font-size: $fontSize - 1;
		line-height: $fontHeight - 1;
		margin: 0;
		padding: 5px 0 0 0;
		max-width: 450px;
		a
		{
			color: $colorRed;
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			text-decoration: underline;
			&:hover
			{
				color: $colorRed;
				text-decoration: underline;
			}
		}
		&.field-validation-error 
		{
			display: block;
		}
	}
	&--tight
	{
		margin-bottom: 0;
	}
}

.form-success
{
	background: $colorGreen;
	color: $colorWhite;
}