﻿@import "../config";

.m-block
{
	background: $colorPrimary;
	color: $colorWhite;
	margin: 0;
	padding: 40px 15px;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		text-align: center;
		max-width: $maxPageWidth;
	}
	&__title
	{
		color: $colorWhite;
		display: inline-block;
		line-height: 48px;
		margin: 0 0 15px 0;
		padding: 0 0 10px 0;
		position: relative;
		z-index: 1;
		@include font-heading-alpha();
		&:before
		{
			@include bar-underline($colorDivider, 1px, 100px, 50%, -50px);
		}
		@include mq-min($bpTabletLarge)
		{
			margin-bottom: 35px;
			padding-bottom: 15px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 40px 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 40px 35px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 40px 80px;
	}
}

.m-block--off-white {
	background: $colorGreyOffWhite;
	color: $colorBaseLight;

	.m-block__title {
		color: $colorBaseLight;
		padding-bottom: 0;

		&:before {
			display: none;
		}
	}
}

.m-block__content {

	.cms {

		* {

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}