﻿@import "../config";

.bx
{
	&-prev, &-next
	{
		background: $colorPrimary;
		display: block;
		height: 60px;
		margin: -30px 0 0 0;
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 50%;
		width: 46px;
		z-index: 1000;
		&:before
		{
			@include bg('/images/common/sprite.png', no-repeat, -221px 0);
			content: "";
			display: block;
			height: 37px;
			margin: -18px 0 0 0;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 16px;
			z-index: 1;
		}
		&:hover
		{
			.no-touchevents &
			{
				width: 39px;
			}			
		}
	}
	&-prev
	{
		left: 0;
		&:before
		{
			right: 14px;
		}
	}
	&-next
	{
		right: 0;
		&:before
		{
			background-position: -248px 0;
			left: 14px;
		}
	}
}