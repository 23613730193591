﻿@import "../config";

.m-rates
{
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__content
	{
		margin: 0;
		padding: 0;
		text-align: center;
		&__title
		{
			color: $colorPrimary;
			font-size: $fontSize + 7;
			font-weight: normal;
			line-height: $fontHeight + 7;
			margin: 0 0 15px 0;
			padding: 0 0 10px 0;
			text-align: center;
			position: relative;
			z-index: 1;
			&:before
			{
				@include bar-underline($colorAction, 1px, 100px, 50%, -50px);
			}
			@include mq-min($bpTabletLarge)
			{
				margin-bottom: 35px;
				padding-bottom: 15px;
			}
		}
	}
	&__table
	{
		margin: 0;
		padding: 0;
		table
		{
			tr
			{
				th, td
				{
					text-align: center;
				}
			}
			tbody
			{
				tr
				{
					&.booked
					{
						th, td
						{
							color: $colorGreyLight;
							&.rate
							{
								text-transform: uppercase;
							}
						}					
					}
				}		
			}
		}
	}
}