﻿@import "../config";

.m-gallery
{
	background: $colorPrimary;
	bottom: 0;
	@include box-sizing();
	display: none;
	left: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100;
	&__title
	{
		display: block;
		height: 67px;
		margin: 0 auto;
		padding: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 200;
		&__link
		{
			@include box-sizing();
			color: $colorWhite;
			display: block;
			font-size: $fontSize - 1;
			height: 100%;
			line-height: $fontHeight - 1;
			margin: 0;
			overflow: hidden;
			padding: 23px 75px 0 20px;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;
			z-index: 1;
			&:before
			{
				@include bg('/images/common/sprite.png', no-repeat, -86px 0);
				content: "";
				display: block;
				height: 25px;
				margin: 0;
				padding: 0;
				position: absolute;
				right: 20px;
				top: 20px;
				width: 37px;
			}
			&:hover
			{
				color: $colorWhite;
				@include opacity();
				text-decoration: none;		
			}
			&.active
			{
				&:before
				{
					background-position: -47px 0;
					height: 29px;
					right: 17px;
					top: 18px;
				}				
			}
			@include mq-max($bpTablet - 1)
			{
				padding-left: 0;
				@include text-hidden();
			}
			&.dark
			{
				background-color: $colorPrimary;
			}
		}
		.js &
		{
			@include mq-max($bpMobileLarge - 1)
			{
				display: none !important;
			}
			@include mq-min($bpMobileLarge)
			{
				display: block;
				@media screen and (max-height: 352px)
				{
					display: none !important;
				}
			}
			@include mq-min($bpTablet)
			{
				@media screen and (max-height: 420px)
				{
					display: none !important;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				@media screen and (max-height: 516px)
				{
					display: none !important;
				}
			}
			@include mq-min($bpDesktop)
			{
				@media screen and (max-height: 622px)
				{
					display: none !important;
				}
			}
			@include mq-min($bpDesktopLarge)
			{
				@media screen and (max-height: 668px)
				{
					display: none !important;
				}
			}
			@include mq-min($bpLarge)
			{
				@media screen and (max-height: 728px)
				{
					display: none !important;
				}
			}
			@include mq-min($bpLarge + 120)
			{
				@media screen and (max-height: 808px)
				{
					display: none !important;
				}
			}
		}
	}
	&__inner
	{
		bottom: 15px;
		left: 15px;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 1;
	}
	&__content
	{
		left: 50%;
		margin: -126px 0 0 -225px;
		padding: 0;
		position: absolute;
		top: 50%;
		width: 450px;
		z-index: 1;
		@include mq-min($bpTablet)
		{
			margin-left: -285px;
			margin-top: -160px;
			width: 570px;
		}
		@include mq-min($bpTabletLarge)
		{
			margin-left: -370px;
			margin-top: -208px;
			width: 740px;
		}
		@include mq-min($bpDesktop)
		{
			margin-left: -465px;
			margin-top: -261px;
			width: 930px;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin-left: -500px;
			margin-top: -282px;
			width: 1000px;
		}
		@include mq-min($bpLarge)
		{
			margin-left: -550px;
			margin-top: -310px;
			width: 1100px;
		}
		@include mq-min($bpLarge + 120)
		{
			margin-left: -640px;
			margin-top: -360px;
			width: 1280px;
		}
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			display: none;
			margin: 0;
			padding: 0;
			&:first-child
			{
				display: block;
			}
		}
		&__image
		{
			height: auto;
			margin: 0;
			padding: 0;
			width: 100%;
		}
	}
	&__loader
	{
		@include bg('/images/common/nav-loader.gif', no-repeat, 50% 50%);
		@include box-sizing();
		height: 100%;
		left: 0;
		margin: 0;
		padding: 10px;
		position: absolute;
		text-align: center;
		@include text-hidden();
		top: 0;
		width: 100%;
		z-index: 2000;
	}
	.js &
	{
		@include mq-max($bpMobileLarge - 1)
		{
			display: none !important;
		}
		@include mq-min($bpMobileLarge)
		{
			@media screen and (max-height: 352px)
			{
				display: none !important;
			}
		}
		@include mq-min($bpTablet)
		{
			@media screen and (max-height: 420px)
			{
				display: none !important;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			@media screen and (max-height: 516px)
			{
				display: none !important;
			}
		}
		@include mq-min($bpDesktop)
		{
			@media screen and (max-height: 622px)
			{
				display: none !important;
			}
		}
		@include mq-min($bpDesktopLarge)
		{
			@media screen and (max-height: 668px)
			{
				display: none !important;
			}
		}
		@include mq-min($bpLarge)
		{
			@media screen and (max-height: 728px)
			{
				display: none !important;
			}
		}
		@include mq-min($bpLarge + 120)
		{
			@media screen and (max-height: 808px)
			{
				display: none !important;
			}
		}
	}
}