@import "../config";

.l-nav {
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 8888;

	&__inner {
		background-color: $colorPrimary;
		background-image: url('/images/logos/sanzaru.png');
		background-position: 50% 20px;
		background-repeat: no-repeat;
		background-size: 116px 91px;
		@include box-sizing();
		color: $colorWhite;
		bottom: 0;
		left: -340px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: fixed;
		text-align: left;
		top: 0;
		width: 320px;
		z-index: 2;

		@include mq-max($bpTabletLarge - 1) {
			left: -100%;
			width: 100%;
		}
	}

	&__title {
		display: block;
		height: 67px;
		left: 0;
		margin: 0 auto;
		padding: 0;
		position: fixed;
		top: 0;
		z-index: 3;

		&__link {
			@include box-sizing();
			color: $colorWhite;
			display: block;
			font-size: $fontSize - 1;
			height: 100%;
			line-height: $fontHeight - 1;
			margin: 0;
			overflow: hidden;
			padding: 23px 20px 0 75px;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;
			@include transition(background-color .2s);
			z-index: 1;

			&:before {
				@include bg('/images/common/sprite.png', no-repeat, 0 0);
				content: "";
				display: block;
				height: 25px;
				left: 20px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 21px;
				width: 37px;
			}

			&:hover {
				color: $colorWhite;
				@include opacity();
				text-decoration: none;
			}

			@include mq-max($bpTablet - 1) {
				padding-right: 0;
				@include text-hidden();
			}

			&.loading {
				background: none;
				@include opacity(.5);

				&:before {
					@include bg('/images/common/nav-loader.gif', no-repeat, 50% 50%, !important);
					height: 32px;
					left: 19px;
					@include opacity(1);
					top: 18px;
				}
			}

			&.active {

				.l-nav__title__link__text {
					display: none;
				}

				&:before {
					background-position: -47px 0;
					height: 29px;
					left: 25px;
					top: 20px;
					width: 29px;
				}
			}
		}
	}

	&__content {
		bottom: 15px;
		left: 0;
		margin: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding: 0;
		position: absolute;
		right: 0;
		top: 150px;
		z-index: 1;
	}

	&__menu {
		height: 100%;
		margin: 0;
		padding: 0;

		&__item {
			margin: 0;
			padding: 0;
		}

		&__link {
			color: $colorWhite;
			display: block;
			font-size: 20px;
			line-height: 25px;
			font-weight: 500;
			margin: 0;
			padding: 10px 25px 10px 55px;
			text-decoration: none;

			&:hover {
				color: $colorWhite;
				color: rgba($colorWhite, .5);
				text-decoration: none;
			}

			&.active {
				color: $colorWhite;
				color: rgba($colorWhite, .5);
			}
		}
	}

	&__sub {
		display: none;
		margin: 0;
		padding: 0 0 5px 0;

		&__menu {
			margin: 0;
			padding: 0 0 5px 0;

			&__item {
				margin: 0;
				padding: 0;
			}

			&__link {
				color: $colorWhite;
				display: block;
				font-size: 17px;
				line-height: 22px;
				font-weight: 500;
				margin: 0;
				padding: 5px 25px 5px 65px;
				position: relative;
				text-decoration: none;
				z-index: 1;

				&:hover, &.active {
					color: $colorWhite;
					color: rgba($colorWhite, .5);
					text-decoration: none;

					&:before {
						background: rgba($colorWhite, .5);
					}
				}
			}
		}

		&.active {
			display: block;
		}
	}

	&__fade {
		background: $colorBlack;
		display: none;
		height: 100%;
		left: 0;
		margin: 0;
		@include opacity(.1);
		padding: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}