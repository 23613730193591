@import "config";
@import "base/forms";

// section
.form-section
{
	margin: 0 -15px 30px -15px;
	padding: 0 15px 20px 15px;
	position: relative;
	z-index: 1;
	&:before
	{
		border-top: 1px solid #d6d6d6;
		bottom: 0;
		content: "";
		display: block;
		height: 0;
		margin: auto;
		padding: 0;
		position: absolute;
		width: calc(100% - 30px);
		z-index: 1;

		@include mq-min($bpDesktopLarge)
		{
			margin-left: -5%;
			margin-right: -5%;
			width: 110%;
		}

		@include mq-min(1450px)
		{
			margin-left: -25%;
			margin-right: -25%;
			width: 150%;
		}
	}
	&__title
	{
		color: $colorBase;
		
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		margin: 0;
		padding: 0 0 20px 0;
		text-align: center;

		@include mq-min($bpDesktop)
		{
			font-size: 20px;
			line-height: 25px;
			padding: 0 0 30px 0;
		}
	}
	&__intro
	{
		margin: 0;
		padding: 0 0 15px 0;
		text-align: center;
	}
	&__action
	{
		margin: 0;
		padding: 0;
		text-align: center;
	}
	@include mq-min($bpTabletLarge)
	{
		margin-bottom: 20px;
		padding-bottom: 20px;
	}
	@include mq-min($bpDesktop)
	{
		margin-bottom: 30px;
		padding-bottom: 30px;
	}
	&--no-divider
	{
		padding-bottom: 0;
		&:before
		{
			display: none;
		}
	}
	&--tight
	{
		margin-bottom: 0;
		padding-bottom: 0;
		&:before
		{
			display: none;
		}
	}
}

// rows
.form-row
{
	padding-bottom: 20px;
	&--group
	{
		margin-top: -10px;
		> .form-row
		{
			label
			{
				@extend .offscreen;
			}
		}
		&:first-child
		{
			margin-top: 0;
		}
	}
}

.form-row__container-tight {
	margin-bottom: -20px;
}

// action
.form-action
{
	&--divider
	{
		margin: 20px 0 0 0;
		padding: 20px 0 0 0;
		position: relative;
		z-index: 1;
		&:before
		{
			@include bar-underline($colorDivider, 1px, 100px);
			bottom: auto;
			top: 0;
		}
		&--centralised
		{
			&:before
			{
				left: 50%;
				margin-left: -50px;
			}
		}
	}
	&--centralised
	{
		text-align: center;
	}
}

// labels
label
{
	a
	{
		color: $colorLink;
		text-decoration: underline;
		&:hover
		{
			color: $colorLink;
			text-decoration: none;
		}
	}
	.form-row &, .form-checks &
	{
		font-size: $fontSize;
		line-height: $fontHeight;
	}
}

// inputs
.form-text, .form-textarea, .form-select
{
	padding: 10px;
	max-width: 100%;
}

.form-text, .form-select
{
	height: 45px;
}

.form-textarea
{
	@include mq-min($bpDesktop)
	{
		height: 200px;
	}
}

// checks
.form-checks
{
	&__item
	{
		label
		{
			color: $colorBase;
			padding: 0;
		}
		input[type="checkbox"]
		{
			visibility: hidden;
			& ~ span
			{
				display: block;
				@include min-height(25px);
				margin: 0;
				padding: 3px 0 0 35px;
				&:before
				{
					background: $colorWhite;
					border: 1px solid $colorBorder;
					content: "";
					cursor: pointer;
					display: block;
					height: 26px;
					left: 0;
					margin: 0;
					padding: 0;
					position: absolute;
					top: 0;
					width: 26px;
					z-index: 1;
				}
				&:hover
				{
					&:before
					{
						border-color: darken($colorBorder, 15%);
					}
				}
			}
			&:focus
			{
				&:hover
				{
					& ~ span
					{
						&:before
						{
							border-color: darken($colorBorder, 5%);
						}
					}
				}
			}
			&:checked
			{
				& ~ span
				{
					&:before
					{
						
						background-color: $colorWhite;
						border-color: darken($colorBorder, 15%);
					}
					&:after
					{
						@include bg('/images/common/sprite.png', no-repeat, 0 -125px);
						content: "";
						display: block;
						height: 16px;
						left: 4px;
						margin: 0;
						padding: 0;
						position: absolute;
						top: 6px;
						width: 20px;
						z-index: 1;
					}
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 10px;
		}
	}
	&--inline
	{
		&__item
		{
			&:first-child
			{
				@include mq-min($bpTabletLarge)
				{
					padding-top: 10px;
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			margin-top: -10px;
		}
	}
	&--tight
	{
		padding-bottom: 0;
	}
}

// buttons
.form-button
{
	background: none;
	border-color: $colorAction;
	color: $colorAction;
	font-size: $fontSize;
	line-height: $fontSize;
	text-transform: uppercase;
	font-weight: 700;
	&:hover
	{
		background: $colorAction;
		border-color: $colorAction;
		color: $colorWhite;
	}
	@include mq-min($bpDesktop)
	{
		min-width: 180px;
	}
	&--alt
	{
		background: $colorAction;
		border-color: $colorAction;
		color: $colorWhite;
		&:hover
		{
			background: none;
			border-color: $colorAction;
			color: $colorAction;
		}
	}
	&--clear
	{
		background: none;
		border-color: $colorWhite;
		color: $colorWhite;
		&:hover
		{
			background: $colorWhite;
			border-color: $colorWhite;
			color: $colorLink;
		}
		&--accent
		{
			&:hover
			{
				color: $colorAccent;
			}
		}
		&--accent-alt
		{
			&:hover
			{
				color: $colorAccentAlt;
			}
		}
	}
	&--text
	{
		background: none !important;
		border-color: transparent !important;
		color: $colorLink;
		padding: 10px 0;
		min-width: unset;
		text-decoration: underline;
		text-transform: none;
		&:hover
		{
			color: $colorLinkHover;
			text-decoration: none !important;
		}
	}
	&[disabled], &.disabled
	{
		background: $colorGreyLight;
		color: $colorGrey;
	}
}

// info
.form-info
{
	color: $colorGreyLight;
	font-family: $fontSimple;
	font-size: $fontSize - 2;
	line-height: $fontHeight - 2;
}

// messages
.form-error
{
	.form-row &
	{
		font-family: $fontSimple;
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
	}
}

// ui datepicker
.ui-datepicker 
{
	border: 0;
	@include border-radius(0);
	@include box-shadow(0 2px 2px rgba(0,0,0,.2));
	font-family: $fontBase;
	font-size: $fontSize - 2;
	line-height: $fontHeight - 2;
	margin: 0;
	padding: 5px;
	width: 230px !important;
	z-index: 5555 !important;
	.ui-datepicker-header 
	{
		border: 0;
		height: 30px;
		margin: 0;
		padding: 0;
	}
	.ui-datepicker-title 
	{
		background: $colorPrimary;
		color: $colorWhite;
		font-family: $fontSimple;
		font-size: $fontSize - 3;
		font-weight: bold;
		height: 24px;
		line-height: $fontHeight - 3;
		margin: 0;
		padding: 6px 30px 0 30px;
		text-align: center;
		text-transform: uppercase;
	}
	.ui-datepicker-next, .ui-datepicker-prev
	{
		background: $colorPrimary;
		border: 0;
		@include border-radius(0);
		cursor: pointer;
		height: 30px;
		margin: 0;
		padding: 0;
		position: absolute;
		@include text-hidden();
		top: 0;
		width: 30px;
		z-index: 1;
		.ui-icon
		{
			@include bg('/images/common/sprite.png', no-repeat, -221px -10px);
			content: "";
			display: block;
			height: 16px;
			left: 7px;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 7px;
			width: 16px;
			z-index: 1;
		}
		&.ui-state-disabled 
		{
			visibility: hidden;
		}
		&.ui-state-hover 
		{
			background: $colorPrimary;
			@include opacity(1);
			.ui-icon
			{
				@include opacity();
			}
		}
	}
	.ui-datepicker-prev 
	{
		left: 0;
	}
	.ui-datepicker-next 
	{
		right: 0;
		.ui-icon
		{
			background-position: -248px -10px;
		}
	}
	.ui-datepicker-calendar 
	{
		border: 0;
		border-collapse: collapse;
		margin: 0;
		padding: 0;
		table-layout: fixed;
		thead 
		{
			tr 
			{
				th, td 
				{
					color: $colorPrimary;
					font-family: $fontSimple;
					font-size: $fontSize - 3;
					font-weight: bold;
					height: 28px;
					line-height: $fontHeight - 3;
					margin: 0;
					padding: 0;
					text-align: center;
					text-transform: uppercase;
					vertical-align: middle;
					width: 25px;
				}
			}
		}
		tbody 
		{
			tr 
			{
				th, td 
				{
					background: $colorWhite;
					border: 0;
					color: $colorBase;
					font-family: $fontSimple;
					font-size: $fontSize - 3;
					font-weight: normal;
					height: 25px;
					line-height: $fontHeight - 3;
					margin: 0;
					padding: 0;
					text-align: center;
					vertical-align: middle;
					width: 25px;
					span 
					{
						background: lighten($colorDivider, 10%);
						border-color: lighten($colorDivider, 10%);
						color: $colorBase;
						display: block;
						height: 20px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
					}
					a
					{
						display: block;
						height: 20px;
						margin: 1px 0 0 1px;
						padding: 3px 2px 2px 5px;
						text-align: left;
						&.ui-state-default 
						{
							background: lighten($colorDivider, 10%);
							border-color: lighten($colorDivider, 10%);
							color: $colorBase;
						}
						&.ui-state-highlight 
						{
							background: lighten($colorDivider, 5%);
							border-color: darken($colorDivider, 5%);
							color: $colorBase;
						}
						&:hover, &.ui-state-hover 
						{
							background: $colorAction;
							border-color: $colorAction;
							color: $colorWhite;
						}
						&.ui-state-active 
						{
							background: $colorPrimary;
							border-color: $colorPrimary;
							color: $colorWhite;
						}
					}
					&:first-child 
					{
						a 
						{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}