@import "config";

// cms
.cms
{
	h1, h2, h3, h4, h5, h6
	{
		margin: 0;
		padding: 0 0 10px 0;
	}
	h2
	{
		font-size: $fontSize + 7;
		line-height: $fontHeight + 7;
	}
	h3
	{
		font-size: $fontSize + 5;
		line-height: $fontHeight + 5;
	}
	h4
	{
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
	}
	h5
	{
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		padding-bottom: 5px;
	}
	h6
	{
		font-size: $fontSize + 1;
		line-height: $fontHeight + 1;
		padding-bottom: 5px;
	}
	a
	{
		color: $colorLink;
		text-decoration: underline;
		&:hover
		{
			color: $colorLinkHover;
			text-decoration: none;
		}
	}
	ul, ol
	{
		margin: 0;
		padding: 0 0 10px 25px;
		li 
		{
			margin: 0;
			padding: 0 0 5px 0;
		}
		ul, ol 
		{
			margin-bottom: -5px;
			padding-bottom: 0;
			padding-top: 5px;
		}
	}
	ul
	{
		padding-left: 20px;
		> li
		{
			list-style: none;
			position: relative;
			z-index: 1;
			&:before
			{
				background: $colorBase;
				@include border-radius(100%);
				content: "";
				display: block;
				height: 5px;
				left: -20px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 8px;
				width: 5px;
				z-index: 1;
			}
		}
	}
	ol 
	{
		li
		{
			list-style: decimal;
		}		
		ol 
		{
			li
			{
				list-style: lower-alpha;
			}			
			ol 
			{
				li
				{
					list-style: lower-roman;
				}
			}
		}
	}
	blockquote
	{
		background: $colorAccent;
		color: $colorWhite;
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		margin: 0 0 15px 0;
		padding: 10px;
		a
		{
			color: $colorWhite;
			text-decoration: underline;
			&:hover
			{
				color: $colorWhite;
				text-decoration: none;
			}
		}
		p 
		{
			padding-bottom: 5px;
		}
		.quote-author
		{
			color: rgba($colorWhite, .5);
			font-size: $fontSize - 1;
			line-height: $fontHeight - 1;
			padding-left: 15px;
			position: relative;
			z-index: 1;
			a
			{
				color: $colorBase;
				text-decoration: underline;
				&:hover
				{
					color: $colorBase;
					text-decoration: none;
				}
			}
			&:before
			{
				background: rgba($colorWhite, .5);
				content: "";
				display: block;
				height: 1px;
				left: 0;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 10px;
				width: 11px;
				z-index: 1;
			}
		}
		@include mq-min($bpTabletLarge)
		{
			padding: 10px 13px;
		}
	}
	img
	{
		margin: 0 auto 15px auto;
		padding: 0;
		p &
		{
			margin-bottom: 0;
		}
	}
	figure
	{
		margin: 0 auto 15px auto;
		padding: 0;
		img
		{
			margin-bottom: 0;
		}
		figcaption
		{
			color: $colorGreyLight;
			font-size: 85%;
			margin: 0;
			padding: 10px 0 0 0;
		}
	}
	iframe
	{
		display: block;
		margin: 0 0 15px 0;
		padding: 0;
		max-width: 800px;
	}
	table
	{
		margin: 0 0 15px 0;
		padding: 0;
		width: 100%;
		tr
		{
			th, td
			{
				background: $colorOffWhite;
				color: $colorBase;
				font-size: $fontSize;
				line-height: $fontHeight;
				margin: 0;
				padding: 5px;
				text-align: left;
				vertical-align: top;
				@include mq-min($bpTabletLarge)
				{
					padding: 6px 8px;
				}
				@include mq-min($bpDesktopLarge)
				{
					padding: 8px 10px;
				}
			}
		}
		thead
		{
			tr
			{
				th, td
				{
					background: $colorPrimary;
					color: $colorWhite;
					font-size: $fontSize + 1;
					line-height: $fontHeight + 1;
					text-transform: uppercase;
					@include mq-min($bpTabletLarge)
					{
						font-size: $fontSize + 2;
						line-height: $fontHeight + 2;
					}
					@include mq-min($bpDesktopLarge)
					{
						font-size: $fontSize + 4;
						line-height: $fontHeight + 5;
					}
				}
			}		
		}
		tbody
		{
			tr
			{
				&:nth-child(even)
				{
					th, td
					{
						background: darken($colorOffWhite, 5%);
					}
				}
			}		
		}
	}
	&-table
	{
		margin: 0 0 15px 0;
		overflow-x: auto;
		padding: 0 0 10px 0;
		-webkit-overflow-scrolling: touch;
		table
		{
			@include mq-max($bpTablet - 1)
			{
				margin: 0;
				min-width: $bpMobileLarge;
			}
		}
		@include mq-min($bpDesktop)
		{
			margin: 0;
			overflow-x: visible;
			padding: 0;
		}
	}
	&-video
	{
		height: 0;
		margin: 0 auto 15px auto;
		overflow: hidden;
		padding: 35px 0 56.25% 0;
		position: relative;
		max-width: 800px;
		z-index: 1;
		iframe
		{
			display: block;
			height: 100%;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 0;
			width: 100%;
			max-width: 100%;
			z-index: 1;
		}
	}
	.button
	{
		padding-top: 5px;
		a 
		{
			&:hover 
			{
			}
		}		
	}
	&--dark
	{
		color: $colorWhite;
		h1, h2, h3, h4, h5, h6
		{
			color: $colorWhite;
		}
		a
		{
			color: $colorWhite;
			text-decoration: underline;
			&:hover
			{
				color: $colorWhite;
				text-decoration: none;
			}
		}
		ul
		{
			> li
			{
				&:before
				{
					background: $colorAccent;
				}
			}
		}
	}
	&--accent
	{
		color: $colorAccent;
		a
		{
			color: $colorAccent;
			text-decoration: underline;
			&:hover
			{
				color: $colorAccent;
				text-decoration: none;
			}
		}
		ul
		{
			> li
			{
				&:before
				{
					background: $colorAccent;
				}
			}
		}
	}
	&--accent-alt
	{
		color: $colorAccentAlt;
		a
		{
			color: $colorAccentAlt;
			text-decoration: underline;
			&:hover
			{
				color: $colorAccentAlt;
				text-decoration: none;
			}
		}
		ul
		{
			> li
			{
				&:before
				{
					background: $colorAccentAlt;
				}
			}
		}
	}
}