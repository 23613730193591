﻿@import "../config";

.m-property-preview
{
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
	&__inner
	{
		@include gradient-vertical(rgba($colorBlack, 0), rgba($colorBlack, .75));
		bottom: 0;
		@include box-sizing();
		color: $colorWhite;
		left: 0;
		margin: 0;
		padding: 15px 15px 20px 15px;
		position: absolute;
		text-align: left;
		@include text-shadow();
		width: 100%;
		z-index: 2;
		.kwicks-processed &
		{
			@include opacity(0);
		}
		.touchevents .kwicks-processed &
		{
			@include mq-min($bpDesktop)
			{
				@include opacity(0);
			}
		}
		a:hover &
		{
			.no-touchevents &
			{
				@include opacity(1);
			}			
		}
		.kwicks-expanded &, .touchevents .kwicks-expanded &
		{
			@include opacity(1);
		}
		@include mq-min($bpTabletLarge)
		{
			padding: 20px 20px 25px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 30px 30px 35px 30px;
			min-width: 600px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 40px 40px 45px;
		}
		@include mq-min($bpLarge)
		{
			padding: 50px;
		}
	}
	&__link
	{
		display: block;
		height: 300px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		text-decoration: none;
		z-index: 1;
		&:hover
		{
			text-decoration: none;
		}
		@include mq-min($bpMobileLarge)
		{
			height: 400px;
		}
		@include mq-min($bpTabletLarge)
		{
			height: 500px;
		}
		@include mq-min($bpDesktop)
		{
			height: 600px;
		}
	}
	&__title
	{
		font-size: $fontSize + 10;
		font-weight: bold;
		display: block;
		line-height: $fontHeight + 10;
		margin: 0;
		padding: 0;
		position: relative;
		text-transform: uppercase;
		z-index: 1;
		@include mq-min($bpTabletLarge)
		{
			font-size: $fontSize + 13;
			line-height: $fontHeight + 13;
		}
		@include mq-min($bpDesktop)
		{
			font-size: $fontSize + 17;
			line-height: $fontHeight + 17;
		}
		@include mq-min($bpDesktopLarge)
		{
			font-size: $fontSize + 20;
			line-height: $fontHeight + 20;
		}
	}
	&__location
	{
		display: inline-block;
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
		margin: -5px 0 0 0;
		padding: 0 0 10px 0;
		position: relative;
		text-transform: uppercase;
		z-index: 2;
		&:before
		{
			@include bar-underline($colorWhite, 1px, 100%);
			max-width: 100px;
		}
		@include mq-min($bpTabletLarge)
		{
			font-size: $fontSize + 4;
			line-height: $fontHeight + 4;
		}
		@include mq-min($bpDesktop)
		{
			font-size: $fontSize + 6;
			line-height: $fontHeight + 6;
		}
		@include mq-min($bpDesktopLarge)
		{
			font-size: $fontSize + 7;
			line-height: $fontHeight + 7;
		}
	}
	&__summary
	{
		margin: 0;
		padding: 15px 0 0 0;
	}
	&__image
	{
		height: 100%;
		left: 50%;
		margin: -150px 0 0 -300px;
		padding: 0;
		position: absolute;
		top: 50%;
		width: auto;
		max-width: none;
		z-index: 1;
		a:hover &
		{
			@include opacity(1);
		}
		@include mq-min($bpMobileLarge)
		{
			margin: -200px 0 0 -400px;
		}
		@include mq-min($bpTabletLarge)
		{
			margin: -250px 0 0 -500px;
		}
		@include mq-min($bpDesktop)
		{
			margin: -300px 0 0 -600px;
		}
	}
	&__more
	{
		border: 1px solid $colorWhite;
		display: none;
		margin: 15px 0 0 0;
		padding: 5px 15px;
		text-decoration: none;
		@include text-shadow(none);		
		.touchevents .kwicks-processed &
		{
			@include mq-min($bpDesktop)
			{
				display: inline-block;
			}
		}
	}
}