﻿@import "../config";

.l-content
{
	background: $colorWhite;
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		max-width: $maxPageWidth;
		padding-bottom: 40px;
	}
	&__title
	{
		color: $colorPrimary;
		display: inline-block;
		@include font-heading-beta();
		margin: 0 0 15px 0;
		padding: 0 0 10px 0;
		position: relative;
		z-index: 1;
		&:before
		{
			@include bar-underline($colorAction, 1px, 100px);
		}
		@include mq-min($bpTabletLarge)
		{
			margin-bottom: 35px;
			padding-bottom: 15px;
		}
		&--tight
		{
			margin-bottom: 0;
		}
		&--smaller
		{
			font-size: $fontSize + 3;
			line-height: $fontHeight + 3;
		}
		&--centralised
		{
			display: block;
			text-align: center;
			&:before
			{
				left: 50%;
				margin-left: -50px;
			}
		}
		&--offscreen
		{
			height: 0;
			left: -9999px;
			margin: 0;
			padding: 0;
			position: absolute;
			@include text-hidden();
			width: 0;
			z-index: -1;
			&:before
			{
				display: none;
			}
			.m-popup &
			{
				height: auto;
				left: auto;
				margin: 0 0 15px 0;
				overflow: auto;
				padding: 0 0 10px 0;
				position: relative;
				text-indent: 0;
				width: auto;
				z-index: 1;
				&:before
				{
					display: block;
				}
				@include mq-min($bpTabletLarge)
				{
					margin-bottom: 35px;
					padding-bottom: 15px;
				}
			}
		}
	}
	&__summary
	{
		margin: 0;
		padding: 0;
		@include font-size-large();
	}
	&__summary--restricted {
		max-width: 850px;
		margin: 0 auto;
	}
	&__content
	{
		margin: 0;
		padding: 40px 15px;
		@include mq-min($bpTabletLarge)
		{
			padding: 40px 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 40px 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 40px 50px;
		}
	}
}