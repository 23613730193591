﻿@import "../config";

.m-side-nav
{
	margin: 0;
	padding: 0 0 15px 0;
	&__title
	{
		background: $colorPrimary;
		color: $colorWhite;
		cursor: pointer;
		font-size: $fontSize + 2;
		line-height: $fontHeight + 2;
		margin: 0;
		padding: 8px 10px;
		position: relative;
		z-index: 1;
		&:before
		{
			@include bg('/images/common/sprite.png', no-repeat, -147px 0);
			content: "";
			display: none;
			height: 12px;
			left: 10px;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 14px;
			width: 19px;
			z-index: 1;
		}
		@include mq-min($bpTabletLarge)
		{
			display: none !important;
		}
		.js &
		{
			padding-left: 38px;
			&:before
			{
				display: block;
			}
		}
		&.active
		{
			&:before
			{
				background-position: -177px 0;
				height: 16px;
				left: 12px;
				top: 12px;
				width: 16px;
			}
		}
	}
	&__inner
	{
		margin: 0;
		padding: 0;
		.js &
		{
			display: none;
			@include mq-min($bpTabletLarge)
			{
				display: block !important;
			}
		}
	}
	&__content
	{
		background: lighten($colorPrimary, 60%);
		margin: 0;
		padding: 5px 0 0 0;
		@include mq-min($bpTabletLarge)
		{
			background: none;
			padding: 0;
		}
	}
	&__menu
	{
		margin: 0;
		padding: 0;
		&__item
		{
			margin: 0;
			padding: 0;
			@include mq-min($bpDesktop)
			{
				padding-top: 5px;
			}
			@include mq-min($bpDesktopLarge)
			{
				padding-top: 10px;
			}
			&:first-child
			{
				padding-top: 0;
			}
		}
		&__link
		{
			color: $colorGreyLight;
			display: block;
			font-size: $fontSize;
			line-height: $fontHeight;
			margin: 0;
			padding: 5px 10px;
			text-decoration: none;
			text-transform: uppercase;
			&:hover, &.active
			{
				color: $colorPrimary;
				text-decoration: underline;
				@include mq-min($bpTabletLarge)
				{
					text-decoration: none;
				}
			}
			@include mq-min($bpTabletLarge)
			{
				padding: 5px 0;
			}
			@include mq-min($bpDesktop)
			{
				font-size: $fontSize + 1;
				line-height: $fontHeight + 1;
			}
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 5px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 10px;
		}
		&--indented
		{
			display: none;
			padding-left: 10px;
			@include mq-min($bpDesktopLarge)
			{
				padding-left: 15px;
			}
			&.active
			{
				display: block;
			}
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 20px;
		padding-right: 14px;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 35px;
		padding-right: 26px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 50px;
		padding-right: 38px;
	}
	&:last-child
	{
		@include mq-max($bpTabletLarge - 1)
		{
			padding-bottom: 0;
		}		
	}
}