﻿@import "../config";

.m-faqs
{
	margin: 0;
	padding: 0 0 5px 0;
	&__menu
	{
		margin: 0;
		padding: 0;
		&__question
		{
			color: $colorGreyLight;
			margin: 0;
			padding: 0 0 10px 0;
			position: relative;
			z-index: 1;
			&:before
			{
				@include bg('/images/common/sprite.png', no-repeat, -203px 0);
				content: "";
				display: block;
				height: 10px;
				left: -15px;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 6px;
				width: 8px;
				z-index: 1;
				@include mq-min($bpTabletLarge)
				{
					left: -20px;
				}
			}
			&:hover
			{
				.js &
				{
					color: $colorGrey;
					cursor: pointer;
				}
			}
			&.active
			{
				color: $colorGreyLight;
			}
		}
		&__answer
		{
			margin: 0;
			padding: 0;
			&__inner
			{
				margin: 0;
				padding: 0;
			}
			.js &
			{
				display: none;
			}
		}
		.js &
		{
			padding-left: 15px;
			@include mq-min($bpTabletLarge)
			{
				padding-left: 20px;
			}
		}
	}
}