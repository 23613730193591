﻿@import "../config";

.m-masthead
{
	background: $colorPrimary;
	margin: -67px 0 0 0;
	padding: 0;
	position: relative;
	z-index: 1;
	&__inner
	{
		height: 500px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		@include transition(height .2s);
		z-index: 1;
		&:before
		{
			@include gradient-vertical(rgba($colorBlack, .5), rgba($colorBlack, 0));
			content: "";
			display: block;
			height: 100%;
			max-height: 200px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 200;
		}
		@include mq-min($bpTablet)
		{
			height: 550px;
		}
		@include mq-min($bpDesktop)
		{
			height: 600px;
		}
	}
	&__image
	{
		background: $colorPrimary no-repeat 50% 0;
		@include background-size(cover);
		height: 100%;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
	&__video
	{
		@include background-size(cover);
		display: block;
		min-height: 105%;
		height: auto;
		left: 50%;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 50%;
		@include transform(translateX(-50%) translateY(-50%));
		min-width: 105%;
		width: auto;
		z-index: 2;
	}
	&__mute
	{
		bottom: 20px;
		cursor: pointer;
		//display: none;
		height: 40px;
		margin: 0;
		@include opacity(.8);
		padding: 0;
		position: absolute;
		right: 20px;
		width: 40px;
		z-index: 3;
		&:before
		{
			background: url(/images/common/sprite.png) no-repeat -30px -125px;
			content: "";
			display: block;
			height: 20px;
			left: 50%;
			margin: -10px 0 0 -16px;
			padding: 0;
			position: absolute;
			top: 50%;
			width: 27px;
		}
		&.active
		{
			&:before
			{
				background-position: -66px -125px;
			}
		}
		&:hover
		{
			@include opacity(1);
		}
	}
}

.m-masthead__mouse {
	bottom: 40px;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	z-index: 2;
}

.m-masthead__mouse__link {
	border: 2px solid $colorWhite;
	border-radius: 10px;
	display: block;
	height: 35px;
	position: relative;
	width: 20px;
	transition: border 0.25s ease;
	@include text-hidden();

	.touchevents & {
		border: 0;
		width: 35px;
		overflow: visible;

		&:before {
			display: none;
		}

		&:after {
			content: '';
			display: block;
			width: 26px;
			height: 26px;
			border: 2px solid $colorWhite;
			border-width: 0 0 2px 2px;
			transform: rotate(-45deg);
			position: absolute;
			bottom: 0;
			left: 50%;
			margin: 0 0 0 -13px;
		}
	}

	&:before {
		content: '';
		animation-name: mouse;
		animation-duration: 3s;
		animation-iteration-count: infinite;
		background: $colorWhite;
		border-radius: 2px;
		bottom: 21px;
		display: block;
		left: 50%;
		margin: 0 0 0 -2px;
		position: absolute;
		top: 6px;
		width: 4px;
		transition: background 0.25s ease;
	}

	&:hover {
		border-color: $colorAction;

		&:before {
			background: $colorAction;
		}
	}
}

@keyframes mouse {
	20% {
		bottom: 21px;
		top: 6px;
	}

	30% {
		bottom: 14px;
		top: 6px;
	}

	40% {
		bottom: 14px;
		top: 12px;
	}

	45% {
		bottom: 14px;
		top: 12px;
	}

	60% {
		bottom: 21px;
		top: 6px;
	}
}