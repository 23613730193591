@import "config";
@import "base/reset";

// selections
::-moz-selection
{
	background: $colorLink;
	color: #fff;
}
::selection
{
	background: $colorLink;
	color: #fff;
}

// links
a
{
	color: $colorLink;
	text-decoration: none;
	&:hover
	{
		color: $colorLinkHover;
		text-decoration: underline;
	}
}

// headers
h1, h2, h3, h4, h5, h6
{
	@include font-heading-gamma();
	margin: 0;
	padding: 0 0 15px 0;
	@include mq-min($bpTabletLarge)
	{
		padding-bottom: 35px;
	}
}

// content
p
{
	margin: 0;
	padding: 0 0 15px 0;
}

small
{
	color: $colorGreyLight;
	font-size: 85%;
}

// table

table
{
	&.styled
	{
		tr
		{
			th, td
			{
				background: $colorOffWhite;
				color: $colorBase;
				font-size: $fontSize;
				line-height: $fontHeight;
				margin: 0;
				padding: 5px;
				text-align: left;
				vertical-align: top;
				@include mq-min($bpTabletLarge)
				{
					padding: 6px 8px;
				}
				@include mq-min($bpDesktopLarge)
				{
					padding: 8px 10px;
				}
			}
		}
		thead
		{
			tr
			{
				th, td
				{
					background: $colorPrimary;
					color: $colorWhite;
					font-size: $fontSize + 1;
					line-height: $fontHeight + 1;
					text-transform: uppercase;
					@include mq-min($bpTabletLarge)
					{
						font-size: $fontSize + 2;
						line-height: $fontHeight + 2;
					}
					@include mq-min($bpDesktopLarge)
					{
						font-size: $fontSize + 4;
						line-height: $fontHeight + 5;
					}
				}
			}		
		}
		tbody
		{
			tr
			{
				&:nth-child(even)
				{
					th, td
					{
						background: darken($colorOffWhite, 5%);
					}
				}
			}		
		}
	}
}

// utils

.u-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
}