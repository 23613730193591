﻿@mixin bar-underline($color: $colorBase, $height: 1px, $width: 50%, $left: 0, $margin: 0) {
	background: $color;
	bottom: 0;
	content: "";
	display: block;
	height: $height;
	left: $left;
	margin: 0 0 0 $margin;
	padding: 0;
	position: absolute;
	width: $width;
	z-index: 1
}

@mixin font-heading-alpha() {
	font-size: $fontSizeAlpha;
	line-height: $lineHeightAlpha;
	font-weight: $fontWeightAlpha;

	@include mq-min($bpDesktop) {
		font-size: $fontSizeAlphaDesktop;
		line-height: $lineHeightAlphaDesktop;
	}
}

@mixin font-heading-beta() {
	font-size: $fontSizeBeta;
	line-height: $lineHeightBeta;
	font-weight: $fontWeightBeta;

	@include mq-min($bpDesktop) {
		font-size: $fontSizeBetaDesktop;
		line-height: $lineHeightBetaDesktop;
	}
}

@mixin font-heading-gamma() {
	font-size: $fontSizeGamma;
	line-height: $lineHeightGamma;
	font-weight: $fontWeightGamma;

	@include mq-min($bpDesktop) {
		font-size: $fontSizeGammaDesktop;
		line-height: $lineHeightGammaDesktop;
	}
}

@mixin font-size-large() {
	font-size: 16px;
	line-height: 26px;

	@include mq-min($bpDesktop) {
		font-size: 20px;
		line-height: 30px;
	}
}
