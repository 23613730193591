﻿@import "../config";

.m-content-share {
	background: $colorWhite;
	@include box-sizing();
	margin: 0;
	padding: 15px 0 20px 0;
	position: relative;
	z-index: 1;

	&__title {
		font-size: $fontSize + 3;
		line-height: $fontHeight + 3;
		margin: 0;
		padding: 0 0 15px 0;
		text-transform: uppercase;
	}

	&__inner {
		background: $colorWhite;
		margin: 0;
		padding: 15px;
		position: relative;
		z-index: 2;

		@include mq-min($bpTabletLarge) {
			margin-right: 20%;
			padding: 20px;
		}

		@include mq-min($bpDesktop) {
			@include min-height(150px);
			margin-right: 25%;
			padding: 30px;
		}

		@include mq-min($bpDesktopLarge) {
			margin-right: 33.3333%;
			padding: 40px;
		}

		@include mq-min($bpLarge) {
			padding: 50px;
		}
	}

	&__share {
		background: $colorPrimary;
		@include box-sizing();
		margin: 15px 0 0 0;
		padding: 15px;
		position: relative;
		z-index: 2;

		@include mq-min($bpTabletLarge) {
			margin-right: 20%;
		}

		@include mq-min($bpDesktop) {
			background: none;
			bottom: 35px;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 35px;
			text-align: right;
			max-width: 20%;
			z-index: 10;
		}

		@include mq-min($bpDesktopLarge) {
			bottom: 50px;
			right: 50px;
			max-width: 25%;
		}
	}

	&__image {
		background: no-repeat 100% 100%;
		@include background-size(cover);
		bottom: 0;
		display: block;
		height: 100%;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		width: 100%;
		z-index: 1;

		@include mq-max($bpTabletLarge - 1) {
			position: static;
			height: 300px;
			margin-left: -15px;
			margin-right: -15px;
			width: calc(100% + 15px);
		}
	}

	&:not(:last-child) {

		@include mq-min($bpTablet) {
			border-bottom: 20px solid $colorWhite;
		}

		@include mq-min($bpTabletLarge) {
			border-bottom: 20px solid $colorWhite;
		}

		@include mq-min($bpDesktop) {
			border-bottom-width: 35px;
		}

		@include mq-min($bpDesktopLarge) {
			border-bottom-width: 40px;
		}
	}

	@include mq-min($bpTabletLarge) {
		border-bottom: 20px solid $colorWhite;
		padding-bottom: 0;
		padding-top: 80px;
	}

	@include mq-min($bpDesktop) {
		border-bottom-width: 35px;
		padding-top: 150px;
	}

	@include mq-min($bpDesktopLarge) {
		border-bottom-width: 50px;
		padding-top: 200px;
	}

	@include mq-min($bpLarge) {
		padding-top: 300px;
	}

	&--alt {
		&__inner {
			@include mq-min($bpTabletLarge) {
				margin: 0 0 0 20%;
			}

			@include mq-min($bpDesktop) {
				margin: 0 0 0 25%;
			}

			@include mq-min($bpDesktopLarge) {
				margin: 0 0 0 33.3333%;
			}
		}

		&__share {
			@include mq-min($bpTabletLarge) {
				margin-left: 20%;
				margin-right: 0;
			}

			@include mq-min($bpDesktop) {
				bottom: auto;
				left: 35px;
				margin: 0;
				right: auto;
				text-align: left;
				top: 35px;
			}

			@include mq-min($bpDesktopLarge) {
				bottom: auto;
				left: 50px;
				right: auto;
				top: 50px;
			}
		}

		@include mq-min($bpTabletLarge) {
			border: 0;
			border-top: 20px solid $colorWhite;
			padding: 0;
		}

		@include mq-min($bpDesktop) {
			border-top-width: 35px;
			padding-bottom: 50px;
			padding-top: 0;
		}

		@include mq-min($bpDesktopLarge) {
			border-top-width: 50px;
			padding-bottom: 100px;
			padding-top: 0;
		}

		@include mq-min($bpLarge) {
			border-top-width: 40px;
			padding-bottom: 200px;
			padding-top: 0;
		}
	}

	&--centralised {
		text-align: center;
	}
}