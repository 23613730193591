@import "../config";

.l-footer
{
	background: $colorPrimary;
	color: $colorWhite;
	margin: 0;
	padding: 20px 15px;
	&__inner
	{
		@include min-height(120px);
		margin: 0 auto;
		padding: 0;
		position: relative;
		max-width: $maxPageWidth;
		z-index: 1;
	}
	&__top {
		@include mq-min($bpTabletLarge) {
			display: flex;
		}
	}
	&__title
	{
		@include bg('/images/logos/sanzaru.png', no-repeat, 50% 50%);
		content: "";
		display: block;
		height: 124px;
		margin: 0 auto;
		padding: 0;
		@include text-hidden();
		width: 159px;
		z-index: 1;
		@include mq-min($bpTabletLarge) {
			margin: 0;
		}
	}
	&__sitemap
	{
		margin: 20px auto;
		padding: 0;
		&__menu
		{
			font-size: 0;
			line-height: 0;
			@include mq-min($bpTabletLarge)
			{
				margin: 0 0 0 60px;
			}
			padding: 0;
			&__item
			{
				@include box-sizing();
				display: block;
				font-size: 15px;
				line-height: 19px;
				margin: 0 0 10px 0;
				padding: 0;
				text-transform: uppercase;
				z-index: 1;
				text-align: center;
				@include mq-min($bpTabletLarge)
				{
					font-size: 16px;
					line-height: 21px;
					text-align: left;
				}
				&:last-child
				{
					margin-right: 0;
					padding-right: 0;
				}
			}
			&__link
			{
				color: $colorWhite;
				text-decoration: none;
				&:hover
				{
					color: $colorWhite;
					color: rgba($colorWhite, .5);
					text-decoration: none;
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			margin: 0;
			padding: 0;
		}
	}
	&__social
	{
		padding: 0;
		@include mq-min($bpTabletLarge)
		{
			margin-left: auto;
		}
	}
	&__content
	{
		clear: both;
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 20px 0 0 0;
		position: relative;
		z-index: 1;
		@include mq-min($bpDesktop)
		{
			padding: 60px 150px 0 0;
			text-align: left;
		}
	}
	&__menu
	{
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 20px 0 0 0;
		text-align: left;
		&__item
		{
			@include box-sizing();
			display: inline-block;
			font-size: $fontSize - 2;
			line-height: $fontHeight - 2;
			margin: 0 10px 0 0;
			padding: 0 10px 0 0;
			position: relative;
			vertical-align: top;
			z-index: 1;
			&:before
			{
				background:  $colorWhite;
				content: "";
				display: block;
				height: 12px;
				margin: -7px 0 0 0;
				padding: 0;
				position: absolute;
				right: 0;
				top: 50%;
				width: 1px;
			}
			&:last-child
			{
				margin-right: 0;
				padding-right: 0;
				&:before
				{
					display: none;
				}
			}
		}
		&__link
		{
			color: $colorWhite;
			text-decoration: none;
			&:hover
			{
				color: $colorWhite;
				text-decoration: underline;
			}
		}
		@include mq-min($bpDesktop)
		{
			display: inline-block;
			padding: 0;
			vertical-align: top;
		}
	}
	&__copyright, &__credit
	{
		@include box-sizing();
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
		margin: 0;
		padding: 20px 0 0 0;
		&__link
		{
			color: $colorWhite;
			text-decoration: none;
			&:hover
			{
				color: $colorWhite;
				text-decoration: underline;
			}
		}
		@include mq-min($bpMobileLarge)
		{
			max-width: 50%;
		}
		@include mq-min($bpDesktop)
		{
			padding: 0;
		}
	}
	&__copyright
	{
		text-align: left;
		&:before
		{
			background: $colorWhite;
			content: "";
			display: none;
			height: 12px;
			margin: -7px 0 0 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 50%;
			width: 1px;
			@include mq-min($bpDesktop)
			{
				display: block;
			}
		}
		@include mq-min($bpMobileLarge)
		{
			float: left;
		}
		@include mq-min($bpDesktop)
		{
			display: inline-block;
			margin-right: 10px;
			padding-right: 10px;
			position: relative;
			vertical-align: top;
			z-index: 1;
		}
	}
	&__credit
	{
		@include mq-min($bpMobileLarge)
		{
			float: right;
			text-align: right;
		}
		@include mq-min($bpDesktop)
		{
			bottom: 0;
			padding: 0;
			position: absolute;
			right: 0;
			text-align: right;
			width: 150px;
			z-index: 1;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 40px 15px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 60px 15px;
	}
}