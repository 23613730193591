﻿@import "reset";
@import "cms";
@import "forms";


// base
html {
	background: $colorPrimary;
	@include min-height(100%);
	margin: 0;
	padding: 0;
	-webkit-tap-highlight-color: rgba($colorBlack,0);
	@include text-size-adjust();
	min-width: 320px;

	@include mq-min($bpDesktop) {
		min-width: 960px;
	}
}

body {
	background: $colorWhite;
	color: $colorBase;
	font-family: $fontBase;
	font-size: $fontSize;
	height: 100%;
	line-height: $fontHeight;
	margin: 0;
	padding: 0;
	position: relative;
	text-align: center;
	width: 100%;
	min-width: 320px;
	z-index: 1;
	font-weight: 500;

	@include mq-min($bpDesktop) {
		min-width: 960px;
	}

	&.disable-scroll {
		position: fixed;
		overflow-y: scroll;
		width: 100%;
	}

	&.slideout-scroll {
		overflow: hidden;
	}
}


// layout
@import "layout/page";
@import "layout/header";
@import "layout/nav";
@import "layout/content";
@import "layout/back-to-top";
@import "layout/footer";


// plugins
@import "plugins/bx-slider";


// modules
@import "modules/grid";
@import "modules/popups";
@import "modules/masthead";
@import "modules/banner";
@import "modules/block";
@import "modules/content-block";
@import "modules/content-share";
@import "modules/side-nav";
@import "modules/gallery";
@import "modules/property-list";
@import "modules/property-preview";
@import "modules/property";
@import "modules/explore";
@import "modules/tabs";
@import "modules/tab";
@import "modules/faqs";
@import "modules/rates";
@import "modules/feature-grid";
@import "modules/feature";
@import "modules/share";
@import "modules/contact-links";


// helpers
@import "base/helpers";

// fixes
@import "base/fixes";
