﻿@import "../config";

.m-content-block
{
	background: $colorWhite;
	@include box-sizing();
	margin: 0;
	padding: 15px;
	position: relative;
	z-index: 1;
	&__title, &__inner
	{
		background: $colorWhite;
		margin: 0;
		padding: 15px;
		position: relative;
		z-index: 2;
		@include mq-min($bpTabletLarge)
		{
			padding: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 30px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 40px;
		}
		@include mq-min($bpLarge)
		{
			padding: 50px;
		}
	}
	&__title
	{
		padding-bottom: 0;
		.l-content__title
		{
			margin-bottom: 0;
		}
		@include mq-min($bpTabletLarge)
		{
			padding-top: 35px;
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 45px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 55px;
		}
		@include mq-min($bpLarge)
		{
			padding-top: 65px;
		}
	}
	&__inner
	{
		background: $colorWhite;
		@include mq-min($bpTabletLarge)
		{
			margin-right: 20%;
		}
		@include mq-min($bpDesktop)
		{
			@include min-height(150px);
			margin-right: 25%;
		}
		@include mq-min($bpDesktopLarge)
		{
			margin-right: 33.3333%;
		}
	}
	&__image
	{
		background: no-repeat 100% 100%;
		@include background-size(cover);
		bottom: 0;
		display: block;
		height: 100%;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 0;
		width: 100%;
		z-index: 1;
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 0 0 80px 0;
	}
	@include mq-min($bpDesktop)
	{
		padding-bottom: 110px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding-bottom: 140px;
	}
}