@import "../config";

.l-page
{
	background: $colorWhite;
	margin: 0;
	padding: 0;
	&__inner
	{
		margin: 0 auto;
		padding: 0;
		position: relative;
		text-align: left;
		z-index: 2;
	}
	&.slideout-scroll
	{
		min-height: 100%;
		overflow: hidden;
	}
}