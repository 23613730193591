$cssversion: 5;

@import "base/config";

// colours
$colorBase: #5c5b60 !default;
$colorBaseLight: #343434 !default;
$colorOffWhite: #eee !default;
$colorRed: #d95f47 !default;
$colorGreen: #acdc68 !default;
$colorYellow: #ffec1a !default;

// 50 shades
$colorGrey: #707070 !default;
$colorGreyLight: #a6a6a6 !default;
$colorGreyMedium: #8b8b8b !default;
$colorGreyDark: #5a5a5a !default;
$colorDivider: #d0d7df !default;
$colorBorder: #d0d7df !default;
$colorGreyOffWhite: #EBEBEB;
$colorGreyLighter: #F2F2F2;

// custom
$colorPrimary: #65646a !default;
$colorPrimaryAlt: #848388 !default;
$colorAction: #9F1A83 !default;
$colorAccent: #1C7891 !default;
$colorAccentAlt: #71b0c1 !default;

// links
$colorLink: $colorAction;
$colorLinkHover: $colorAction;

// fonts
$fontSimple: Helvetica, Arial, Verdana, sans-serif !default;
$fontBase: 'Quicksand', $fontSimple !default;
$fontSize: 14px !default;
$fontHeight: 22px !default;

$fontSizeAlpha: 26px !default;
$lineHeightAlpha: 33px !default;
$fontSizeAlphaDesktop: 38px !default;
$lineHeightAlphaDesktop: 48px !default;
$fontWeightAlpha: 400;

$fontSizeBeta: 20px !default;
$lineHeightBeta: 25px !default;
$fontSizeBetaDesktop: 24px !default;
$lineHeightBetaDesktop: 30px !default;
$fontWeightBeta: 600;

$fontSizeGamma: 20px !default;
$lineHeightGamma: 25px !default;
$fontSizeGammaDesktop: 20px !default;
$lineHeightGammaDesktop: 25px !default;
$fontWeightGamma: 600;

// responsive breakpoints
$maxPageWidth: 1200px;
$bpMobileLarge: 500px; // for 480px
$bpTablet: 620px; // for 600px
$bpTabletLarge: 788px; // for 768px
$bpDesktop: 980px; // for 960px;
$bpDesktopLarge: 1100px; // for 1080px
$bpLarge: $maxPageWidth + 20;

@keyframes loadingSpinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}