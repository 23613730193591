﻿@import "../config";

.m-banner
{
	background: $colorWhite;
	margin: -67px 0 0 0;
	padding: 0;
	position: relative;
	z-index: 1;
	&__inner
	{
		height: 300px;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: relative;
		@include transition(height .2s);
		z-index: 1;
		&:before
		{
			@include gradient-vertical(rgba($colorBlack, .5), rgba($colorBlack, 0));
			content: "";
			display: block;
			height: 100%;
			max-height: 200px;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 200;
		}
		@include mq-min($bpTablet)
		{
			height: 400px;
		}
		@include mq-min($bpDesktop)
		{
			height: 500px;
		}
		@include mq-min($bpLarge)
		{
			height: 600px;
		}
		&--shallow
		{
			max-height: 400px;
		}
	}
	&__content
	{
		background: $colorGreyOffWhite;
		margin: 0;
		padding: 15px;
		text-align: center;
		@include mq-min($bpTabletLarge)
		{
			padding: 20px;
		}
		@include mq-min($bpDesktop)
		{
			padding: 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding: 40px;
		}
	}
	&__title
	{
		display: inline-block;
		@include font-heading-alpha();
		margin: 0 0 15px 0;
		padding: 0 0 10px 0;
		position: relative;
		z-index: 1;
		&:before
		{
			@include bar-underline($colorBase, 2px, 100px, 50%, -50px);
		}
		@include mq-min($bpTabletLarge)
		{
			margin-bottom: 35px;
			padding-bottom: 15px;
		}
	}
	&__summary
	{
		margin: 0 auto;
		padding: 0;
		max-width: 850px;
		@include mq-min($bpDesktop) {
			font-size: 20px;
			line-height: 30px;
		}
	}
	&__contact
	{
		margin: 0;
		padding: 0;
		@include mq-min($bpTabletLarge)
		{
			padding-top: 5px;
		}
		@include mq-min($bpDesktop)
		{
			padding-top: 20px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 35px;
		}
	}
	&__image
	{
		background: no-repeat 50% 0;
		@include background-size(cover);
		height: 100%;
		left: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}
}