﻿@import "../config";

.m-tab
{
	margin: 0;
	padding: 0 15px 0 15px;
	&__title
	{
		@include box-sizing();
		display: inline-block;
		@include font-heading-beta();
		margin: 0 0 15px 0;
		padding: 0 0 10px 0;
		position: relative;
		text-align: left;
		text-decoration: none;
		z-index: 1;
		&:before
		{
			@include bar-underline($colorDivider, 2px, 40px);
		}
		@include mq-min($bpTabletLarge)
		{
			margin-bottom: 35px;
			padding-bottom: 15px;
			width: 65%;
		}
	}
	&__sub-title
	{
		font-size: $fontSize + 5;
		font-weight: normal;
		line-height: $fontHeight + 5;
		margin: 0;
		padding: 0 0 10px 0;
		@include mq-min($bpTabletLarge)
		{
			font-size: $fontSize + 7;
			line-height: $fontHeight + 7;
			padding-bottom: 50px;
		}
		&--tight
		{
			padding-bottom: 0;
		}
	}
	&__inner
	{
		margin: 0;
		padding: 0;
	}
	&__content
	{
		@include box-sizing();
		margin: 0;
		padding: 0;
		&--with-aside
		{
			padding-bottom: 0;
			@include mq-min($bpTabletLarge)
			{
				float: left;
				width: 65%;
			}
		}
	}
	&__aside
	{
		@include box-sizing();
		margin: 0;
		padding: 0;
		&__inner
		{
			color: $colorAccentAlt;
			margin: 0;
			padding: 15px;
			@include mq-min($bpDesktop)
			{
				padding: 20px;
			}
		}
		&__title
		{
			color: $colorAccent;
			@include box-sizing();
			display: inline-block;
			@include font-heading-gamma();
			margin: 0;
			padding: 0 0 10px 0;

			&:not(:first-child) {
				padding-top: 15px;

				@include mq-min($bpTabletLarge) {
					padding-top: 25px;
				}
			}
		}
		@include mq-min($bpTabletLarge)
		{
			float: right;
			margin-top: -77px;
			padding-left: 20px;
			width: 35%;
		}
		@include mq-min($bpDesktop)
		{
			padding-left: 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-left: 50px;
		}
	}
	@include mq-min($bpTabletLarge)
	{
		padding: 0 20px 0 20px;
	}
	@include mq-min($bpDesktop)
	{
		padding: 0 35px 0 35px;
	}
	@include mq-min($bpDesktopLarge)
	{
		padding: 0 50px 0 50px;
	}
	.js &
	{
		@include mq-min($bpDesktop)
		{
			padding-top: 35px;
		}
		@include mq-min($bpDesktopLarge)
		{
			padding-top: 50px;
		}
	}
}