﻿@import "../config";

.m-tabs {
	margin: 0;
	padding: 0 0 15px 0;

	&__inner {
		margin: 0;
		padding: 0;
	}

	&__nav {
		display: none;
		margin: 0;
		padding: 0 0 20px 0;

		@include mq-min($bpDesktop) {
			float: left;
			padding-bottom: 0;
			width: 150px;
		}

		.js & {
			display: block;
		}
	}

	&__menu {
		font-size: 0;
		line-height: 0;
		margin: 0;
		padding: 0;

		&__item {
			background: $colorGreyLighter;
			border-top: 2px solid $colorWhite;
			display: inline-block;
			margin: 0;
			overflow: hidden;
			padding: 0;
			vertical-align: top;
			width: 100%;

			@include mq-min($bpTablet) {
				width: 20%;
			}

			@include mq-min($bpDesktop) {
				width: 100% !important;
			}

			&:first-child {
				border-top: 0;
			}

			&--of-2 {
				@include mq-min($bpTablet) {
					width: 50%;
				}
			}

			&--of-3 {
				@include mq-min($bpTablet) {
					width: 33.3333%;
				}
			}

			&--of-4 {
				@include mq-min($bpTablet) {
					width: 25%;
				}
			}
		}

		&__link {
			@include box-sizing();
			color: $colorAccent;
			display: block;
			font-size: $fontSize + 2;
			line-height: $fontHeight - 2;
			font-weight: 700;
			margin: 0;
			padding: 10px;
			text-align: left;
			text-decoration: none;
			text-transform: uppercase;

			&__inner {
				display: block;
				margin: 0;
				padding: 0;
				position: relative;
				z-index: 1;

				@include mq-min($bpTablet) {
					@include transform(translateY(-50%));
					top: 50%;
				}
			}

			&:hover, &.active {
				background: $colorAccentAlt;
				color: $colorWhite;
				text-decoration: none;

				@include mq-min($bpTablet) {
					background: $colorWhite;
					color: $colorPrimary;
				}
			}

			&:hover {
				@include mq-min($bpTablet) {
					background: $colorOffWhite;
				}
			}

			&.active {
				font-weight: 700;

				@include mq-min($bpTablet) {
					background: $colorWhite;
				}
			}

			@include mq-min($bpTablet) {
				height: 125px;
				text-align: center;
				font-size: $fontSize;
				line-height: $fontHeight - 4;
				font-weight: 600;
			}
		}
	}

	&__content {
		margin: 0;
		overflow: hidden;
		padding: 0;
	}

	&__actions {
		clear: both;
		margin: 0;
		padding: 15px 0 40px 0;

		@include mq-min($bpTabletLarge) {
			padding: 15px 0;
		}

		&__summary {
			font-weight: 600;
		}

		&__inner {
			margin: 0;
			padding: 20px 0 0 0;
			border-top: 2px dotted $colorDivider;
			text-align: center;

			@include mq-min($bpTabletLarge) {
				padding: 35px 0 0 0;
				text-align: left;
			}
		}

		@include mq-min($bpTabletLarge) {
			padding: 20px 0 15px 0;
		}

		@include mq-min($bpDesktop) {
			padding: 35px 0 15px 0;
		}

		@include mq-min($bpDesktopLarge) {
			padding-top: 50px;
		}
	}

	@include mq-min($bpTabletLarge) {
		padding-bottom: 20px;
	}

	@include mq-min($bpDesktop) {
		padding-bottom: 40px;
	}

	&--tight {
		padding-bottom: 0;
	}
}